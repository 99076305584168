import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import GatsbyImage from "gatsby-image";
import { brand, mq } from "../../../styles"


const PartnerCardElement = styled.div`
  flex-basis: 250px;
  height: 250px;
	text-align: center;
  background-color: #fff;
  display: flex;
  align-items: center;

  span {
    width: 100%;
  }

	@media screen and (min-width: 700px) {
		width: 25%;
	}
`;

const PartnerCard = ({ partner }) => {
  if(!partner.partnerLogo) return null;
  return (
    <>
      <PartnerCardElement
        key={partner}
        data-partner-name={partner.readable}
      >
        <span>
          <GatsbyImage fluid={partner.partnerLogo.childImageSharp.fluid} alt={partner.readable} imgStyle={{
            objectFit: "none",
            objectPosition: "50% 50%",
          }}/>
        </span>
      </PartnerCardElement>
    </>
  );
};

PartnerCard.propTypes = {
  partner: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["simple", "industry"]),
};

PartnerCard.defaultProps = {
  type: "simple",
};

export default PartnerCard;
